<template>
  <section class="abonements-main__benefits">
    <div class="abonements-main__container">
        <h2 class="abonements-main__title abonements-main__title--gap">Что получит владелец абонемента</h2>
        <ul class="abonements-main__benefit-list">
          <li 
            v-for="benefit in abonementsBenefits" 
            :key="benefit.title"
            class="abonements-main__benefit"
          >
            <div class="abonements-main__benefit-img">
              <img :src="benefit.img" :alt="benefit.alt">
            </div>
            <div class="abonements-main__benefit-wrap">
              <h3>{{ benefit.title }}</h3>
              <p>{{ benefit.description }}</p>
            </div>
          </li>
        </ul>
    </div>
  </section>
</template>

<script>
import abonementsBenefits from '@/mocks/abonementsBenefits.json';

export default {
  name: 'AbonementsBenefits',
  data() {
    return {
      abonementsBenefits
    }
  },
}
</script>
