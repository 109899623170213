<template>
  <div class="tickets-system-local__scheam-bubble" v-if="data"
        @mouseleave="$emit('mouseleave')"
        @mouseover="$emit('mouseover')"
        :style="{bottom: data.top + 'px', left: data.left + 'px', transform: 'translate(-50%, ' + (this.direction === 'top' ? '0%' : '100%') + ')'}">
    <div class="inner">   
      <p class="heading-sector">{{ data.name }}</p>
      <p class="heading">Вид из сектора на стадион</p>
      <div class="picture-sector">
        <img onerror="javascript:this.src='/resources/sectorsview/logo_mtg.svg'" :src="'/resources/sectorsview/' + data.sectorId + '.jpg'" alt="">
      </div>
      <div class="list">
        
        <p class="list-heading">Осталось свободных мест: {{ data.freeSeats }}</p>
        <ul class="list-inner">
          <li class="list-item" :key="index" v-for="(price, index) in data.availablePrices">{{ price }} ₽</li>
        </ul>
      </div>
      <router-link :to="{ name: 'sector', params: { calendarId: data.calendarId, sectorId: data.sectorId}, query: { seasonIds: seasonIds} }">
        <button type="button" class="yellow-button">Выбрать билеты</button>
      </router-link>
    </div>  
  </div>
</template>

<script>
export default {
  name: 'SectorInfoLayer',
  data() {
    return {
      direction: 'top'
    }
  },
  props: {
    data: {
      type: Object
    },
    seasonIds: {
      type: Array
    },
  },
  mounted() {
    const bbox = this.$el.getBoundingClientRect();
    if (bbox.top <= 0) {
      this.direction = 'bottom';
    }
  }
}
</script>

<style scoped lang="scss" src="@/styles/SectorInfoLayer.scss">
</style>
