<template>
  <div>
    <div ref="sliderWrapper" class="choice-ticket__slider-wrapper">
      <div 
        :class="{ 'choice-ticket__slider-item choice-ticket__slider-item--event' : calendar.siteBigView == 1 }"
        class="choice-ticket__slider-item"
        v-for="calendar in getCurrentCalendarByMonth"
        :key="calendar.id"
      >
      <div>
        <router-link :to="{ name: 'games', params: { calendarId: calendar.id } } ">
          <div class="item-wrapper">
            <img v-if="calendar.guestTeamLogoLink && (calendar.siteBigView == 0 || !calendar.siteBigView)" class="item-logo" :src="calendar.guestTeamLogoLink" alt=""/>
            <img v-if="!calendar.guestTeamLogoLink && (calendar.siteBigView == 0 || !calendar.siteBigView) && calendar.calendarLogoLink" class="item-logo" :src="calendar.calendarLogoLink" alt=""/>
            <img v-if="!calendar.guestTeamLogoLink && (calendar.siteBigView == 0 || !calendar.siteBigView) && !calendar.calendarLogoLink" class="item-logo-empty" src="@/_assets/redesign2020/img/test/empty-logo.svg" alt=""/>
            
            <img v-if="calendar.siteBigView == 1" class="item-background-picture" :src="calendar.siteBigViewLogoLink" alt=""/>
            <div class="item-name">{{ calendar.guestTeamName || calendar.fullName }}</div>
            <div v-if="calendar.algorithmSaleId == 510" class="item-date">{{ moment(calendar.day).locale('ru').format('DD MMMM, dd') }}</div>
            <div v-if="calendar.algorithmSaleId == 520" class="item-date"/>
            <div class="item-bottom">
              <img v-if="calendar.siteBigView == 0 || !calendar.siteBigView" class="item-league" src="/resources/khl-logo/khl_logo.png" alt="">
              <div class="item-inner">
                <span v-if="calendar.algorithmSaleId == 510" class="item-time">{{ calendar.time.substr(0, 5) }}</span>
                <span v-if="calendar.algorithmSaleId == 520" class="item-time"/>
                <span v-if="calendar.availSeatQuant > 0" class="item-tickets">{{ calendar.availSeatQuant }} билетов</span>
              </div>  
            </div>  
          </div>
        </router-link>
      </div>
      </div>
    </div>
    <div class="choice-ticket__section">
      <div class="choice-ticket__slider-downloads">
        <a class="choice-ticket__slider-link" href="https://www.metallurg.ru/upload/KHL_MMG.pdf" target="_blank">
          <svg>
            <use xlink:href="#ic_download"></use>
          </svg>
          Скачать расписание матчей
        </a>
      </div>
      <div class="choice-ticket__slider-navigation">
        <button class="choice-ticket__slider-prev" @click="prevSlide">
          <svg>
            <use xlink:href="#ic_arrow-next"></use>
          </svg>
        </button>
        <ul class="choice-ticket__slider-bullets">
          <li class="choice-ticket__slider-bullet active"><button type="button"></button></li>
          <li class="choice-ticket__slider-bullet"><button type="button"></button></li>
          <li class="choice-ticket__slider-bullet"><button type="button"></button></li>
        </ul>
        <button class="choice-ticket__slider-next" @click="nextSlide">
          <svg>
            <use xlink:href="#ic_arrow-next"></use>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventCards',
  props: {
    allCalendar: { type: Array },
    activeMonth: { type: String },
  },
  computed: {
    getCurrentCalendarByMonth() {
      return this.allCalendar?.find(calendar => calendar.monthText === this.activeMonth)?.webTypes.reduce((accumulator, webType) => {
        return accumulator.concat(webType.calendars).filter(calendar =>
          (calendar.algorithmSaleId == 510 || calendar.seasonLocationName == 'TICKETS') || 
          (calendar.algorithmSaleId == 520 && calendar.seasonLocationName == 'TICKETS') ||
          (calendar.algorithmSaleId == 520 && calendar.seasonLocationName == 'MINI') ||
          (calendar.algorithmSaleId == 530 && calendar.seasonLocationName == 'TICKETS')
        )
      }, []);
    },
  },
  methods: {
    prevSlide() {
      this.$refs.sliderWrapper.scrollTo({ left: this.$refs.sliderWrapper.scrollLeft - 500, behavior: 'smooth' });
    },
    nextSlide() {
      this.$refs.sliderWrapper.scrollTo({ left: this.$refs.sliderWrapper.scrollLeft + 500, behavior: 'smooth' });
    }
  }
}
</script>

<style lang="scss">
.choice-ticket {
  &__section {
    display: flex;
    justify-content: space-between;
  }

  &__slider-wrapper {
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__spinner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.item-wrapper {
  cursor: pointer;
}
</style>

<!--
<style lang="scss" src="@/styles/EventCards.scss">
</style> -->
