import {Getters, Mutations, Module, Actions, createMapper} from 'vuex-smart-module';

import calendarApi from '@/services/calendar.api';
export class CalendarState {
	calendar;
	availCalendarList;
	seatsSvg;
	availableSectorsList;
	availableSeatList;
	calendarListById;
	gameInfo;
	sectorInfo;
	reservedSeats;
	seatType;
	bonuses;
	promocode;
}

export class CalendarGetters extends Getters {
	get allCalendar() {
		return this.state.calendar;
	}

	get availCalendarList() {
		return this.state.availCalendarList;
	}

	get calendarSeatsSvg() {
		return this.state.seatsSvg;
	}

	get availableSectorsList() {
		return this.state.availableSectorsList;
	}

	get availableSeatList() {
		return this.state.availableSeatList;
	}

	get calendarListById() {
		return this.state.calendarListById;
	}

	get gameInfo() {
		return this.state.gameInfo;
	}

	get sectorInfo() {
		return this.state.sectorInfo;
	}

	get reservedSeats() {
		return this.state.reservedSeats;
	}

	get seatType() {
		return this.state.seatType;
	}

	get bonuses() {
		return this.state.bonuses;
	}

	get promocode() {
		return this.state.promocode;
	}
}

export class CalendarMutations extends Mutations {

  setCalendar(calendar) {
    this.state.calendar = calendar;
  }

	setAvailCalendarList(availCalendarList) {
    this.state.availCalendarList = availCalendarList;
  }
  
  setCalendarSeatsSvg(seatsSvg) {
    this.state.seatsSvg = seatsSvg;
  }

  setAvailableSectorsList(availableSectorsList) {
		this.state.availableSectorsList = availableSectorsList;
	}

  setAvailableSeatList(availableSeatList) {
		this.state.availableSeatList = availableSeatList;
	}

	setCalendarListById(calendarListById) {
		this.state.calendarListById = calendarListById;
	}

	setGameInfo(gameInfo) {
		this.state.gameInfo = gameInfo;
	}

	setSectorInfo(sectorInfo) {
		this.state.sectorInfo = sectorInfo;
	}

	setReservedSeats(reservedSeats) {
		this.state.reservedSeats = reservedSeats;
	}

	setSeatType(seatType) {
		this.state.seatType = seatType;
	}

	setBonuses(bonuses) {
		this.state.bonuses = bonuses;
	}

	setPromocode(promocode) {
		this.state.promocode = promocode;
	}
}

export class CalendarActions extends Actions {
	async getAllCalendar() {
		// eslint-disable-next-line no-useless-catch
		try {
			const response = await calendarApi.getAllCalendar();
			this.commit('setCalendar', response);
		} catch (error) {
			throw error;
		}
	}

	async getAvailCalendarList() {
		// eslint-disable-next-line no-useless-catch
		try {
			const response = await calendarApi.getAvailCalendarList();
			this.commit('setAvailCalendarList', response);
		} catch (error) {
			throw error;
		}
	}

	async getCalendarListById(calendarId) {
		// eslint-disable-next-line no-useless-catch
		try {
			const response = await calendarApi.getCalendarListById(calendarId);
			this.commit('setCalendarListById', response);
		} catch (error) {
			throw error;
		}
	}

	async getCalendarById(calendarId) {
		// eslint-disable-next-line no-useless-catch
		try {
			const response = await calendarApi.getCalendarById(calendarId);
			this.commit('setGameInfo', response);
		} catch (error) {
			throw error;
		}
	}

	async getCalendarSchemeSvg(calendarId) {
		try {
			const response = await calendarApi.getSeatsSvg(calendarId);
			this.commit('setCalendarSeatsSvg', response);
		} catch ({message}) {
			this.commit('setCalendarSeatsSvg', []);
		}
	}

	async getAvailableSectorsList({calendarId, searchFilter}) {
		// eslint-disable-next-line no-useless-catch
		try {
			const response = await calendarApi.getAvailableSectorsList({calendarId, searchFilter});
			this.commit('setAvailableSectorsList', response);
		} catch (error) {
			throw error;
		}
	}

	async getAvailableSeatList([calendarId, sectorId]) {
		try {
			const response = await calendarApi.getAvailableSeatList(calendarId, sectorId);
			this.commit('setAvailableSeatList', response);
		} catch ({message}) {
			this.commit('setAvailableSeatList', []);
		}
	}

	async getSchemeBySectorId({calendarId, sectorId, seasonIds}) {
		// eslint-disable-next-line no-useless-catch
		try {
			const response = await calendarApi.getSchemeBySectorId(calendarId, sectorId, seasonIds);
			this.commit('setSectorInfo', response);
		} catch (error) {
			throw error;
		}
	}

	async getReservedSeatsList() {
		try {
			const response = await calendarApi.getReservedSeatsList();
			this.commit('setReservedSeats', response);
		} catch ({message}) {
			this.commit('setReservedSeats', []);
		}
	}

	async reserveSeat({calendarId, reserveInfo}) {
			const response = await calendarApi.reserveSeat({calendarId, reserveInfo});
			const reservedSeats = this.state.reservedSeats ? this.state.reservedSeats.slice(0) : [];
			reservedSeats.push(response)
			this.commit('setReservedSeats', reservedSeats);
	}

	async unreserveSeat({calendarId, unreserveInfo}) {
		try {
			await calendarApi.unreserveSeat({calendarId, unreserveInfo});
			const reservedSeats = this.state.reservedSeats ? this.state.reservedSeats.slice(0) : [];
			const seat = reservedSeats.find(s => s.id === +unreserveInfo.seatId && s.calendarId === +calendarId);
			if (seat) {
				reservedSeats.splice(reservedSeats.indexOf(seat), 1);
				this.commit('setReservedSeats', reservedSeats);
			}
		} catch ({message}) {
			throw new Error(message);
		}
	}

	async setEmptyReservedSeats() {
        this.commit('setReservedSeats', []);
    }

	async toggleSeatType({calendarId, seatId, isChild}) {
		return calendarApi.toggleSeatType({calendarId, seatId, isChild});
	}

	async getBonuses() {
		try {
			const response = await calendarApi.getBonuses();
			this.commit('setBonuses', response);
		} catch ({message}) {
			this.commit('setBonuses', []);
		}
	}

	async checkPromoCode([calendarId, promocode]) {
		try {
			const response = await calendarApi.checkPromoCode(calendarId, promocode);
			this.commit('setPromocode', response);
		} catch ({message}) {
			throw message;
		}
	}

	async createOrder(model) {
		return await calendarApi.createOrder(model);
	}
}

export const calendarModule = new Module({
	state: CalendarState,
	getters: CalendarGetters,
	mutations: CalendarMutations,
	actions: CalendarActions,
});

export const calendarMapper = createMapper(calendarModule);

export default calendarModule;
