import axios from 'axios';

export default {
	async getSeatInOrder(orderId) {
		try {
			const url = `/webapi/orders/${orderId}/seats`;
			const response = await axios.get(url);
			return response.data;
		} catch {
			throw new Error('Failed to get seat in order. Please try again later.');
		}
	},
	async printOrderTickets(orderId) {
		try {
			const url = `/webapi/tickets/pdf/print/order/${orderId}`;
			const response = await axios.post(url, null, {
				responseType: 'blob'
			});

			return response.data;
		} catch (error) {
			const response = JSON.parse(await error.response.data.text());
			throw new Error(response.errorMessage);
		}
	},
}
