<template>
  <div class="subheader">
    <ul class="subheader__list">
      <li class="subheader__list-item">
        <router-link :to="{ name: 'ticket' }">
          <a class="subheader__list-item-link">Билеты</a>
        </router-link>  
      </li>
      <li class="subheader__list-item">
        <router-link :to="{ name: 'abonements' }">
          <a class="subheader__list-item-link">Абонементы</a>
        </router-link>  
      </li>
      <!-- <li class="subheader__list-item">
        <a>Карта точек продаж</a>
      </li>
      <li class="subheader__list-item">
        <a>VIP-ложи</a>
      </li>
      <li class="subheader__list-item">
        <a>Выездные матчи</a>
      </li> -->
    </ul>
  </div>
</template>

<script>

export default {
  name: 'SubHeader',
}
</script>

<style lang="scss" src="@/styles/SubHeader.scss">
</style>
