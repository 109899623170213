import Vue from 'vue';
import * as Vuex from 'vuex';
import { Module, createStore } from 'vuex-smart-module';

import calendar from '@/store/modules/calendar';
import order from '@/store/modules/order';
import authorization from '@/store/modules/authorization';
import orderpayemail from '@/store/modules/orderpayemail';


Vue.use(Vuex);

const rootModule = new Module({
  modules: {
    calendar,
    order,
    authorization,
    orderpayemail
  },
});

const store = createStore(rootModule);

export default store;
