<template>
  <div class="abonements">
    <div class="abonements-main">
      <div v-if="!availCalendarList" class="abonements__spinner">
        <half-circle-spinner
          v-if="isContentLoading"
          :animation-duration="2000"
          :size="60"
          :color="'#0a275f'"
        />
      </div>
      <div v-else>
        <AbonementsBanner @scrollToKinds="scrollToKinds"/>
        <AbonementsBenefits />          
        <AbonementsKinds id="abonementsKinds"/> 
        <AbonementsFAQ /> 
        <!--
        <AbonementsScheme /> 
        <AbonementsWrite />
        -->
      </div>
      <ErrorPopup
        v-if="isErrorPopupShown"
        @closeErrorPopup="closeErrorPopup"
        :errorMessage="errorMessage"
      />
    </div>
  </div>
</template>

<script>
import { calendarMapper } from '@/store/modules/calendar';

import { HalfCircleSpinner } from 'epic-spinners';
import AbonementsBanner from './AbonementsBanner.vue';
import AbonementsBenefits from './AbonementsBenefits.vue';
import AbonementsKinds from './AbonementsKinds.vue';
import AbonementsFAQ from './AbonementsFAQ.vue';
//import AbonementsScheme from './AbonementsScheme.vue';
//import AbonementsWrite from './AbonementsWrite.vue';
import ErrorPopup from '../ErrorPopup.vue';

export default {
  name: 'Abonements',
  components: {
    AbonementsBanner,
    AbonementsBenefits,
    AbonementsKinds,
    AbonementsFAQ,
    //AbonementsScheme,
    //AbonementsWrite,
    HalfCircleSpinner,
    ErrorPopup,
  },
  data() {
    return {
      errorMessage: '',
      isErrorPopupShown: false,
      isContentLoading: false,
    }
  },
  async mounted() {
    try {
      this.isContentLoading = true,
      await this.getAvailCalendarList();
    } catch(error) {
      if (error.status === 502) {
        this.errorMessage = "Ошибка связи с сервером.";
        this.isErrorPopupShown = true;
      }
    } finally {
      this.isContentLoading = false; 
    }
  },
  computed: {
    ...calendarMapper.mapGetters(['availCalendarList']),
  },
  methods: {
    ...calendarMapper.mapActions(['getAvailCalendarList']),
    scrollToKinds() {
      const paddingAndHeaderHeight = 160;
      let slide = document.querySelector('#abonementsKinds').offsetTop - paddingAndHeaderHeight;
      window.scroll({ top: slide, behavior: "smooth" });
    },
    closeErrorPopup(isShowModal) {
      this.isErrorPopupShown = isShowModal;
    },
  }
}
</script>

<style lang="scss" src="@/styles/Abonements.scss">
</style>
