<template>
  <div class="error-popup">
    <div class="error-popup__wrapper">
      <div class="error-popup__close-button-wrapper">
        <button class="error-popup__close-button" @click="closeErrorPopup">
          <svg class="close error-popup__close-icon">
            <use xlink:href="#ic_close"></use>
          </svg>
        </button>
      </div>
      <div class="error-popup__logo-wrapper">
        <img src="https://www.metallurg.ru/_assets/redesign2020/img/official/small-logo.svg" alt="Логотип ХК Металлург" loading="lazy" width="180" height="180">
      </div>
      <span class="error-popup__title">{{ errorMessage }}</span>
      <div class="error-popup__button-wrapper">  
        <button v-if="isAuthorizationError" class="yellow-button" @click="authorizationHandler">
          Авторизоваться
        </button>
        <button v-else class="yellow-button" @click="closeErrorPopup">
          Понятно
        </button>
      </div>  
    </div>
    <div class="overlay" @click="closeErrorPopup"></div>
  </div>
</template>

<script>
export default {
  name: 'ErrorPopup',
  props: {
    errorMessage: { type: String },
    isAuthorizationError: { type: Boolean },
  },
  methods: {
    closeErrorPopup() {
      this.$emit('closeErrorPopup', false);
    },
    authorizationHandler() {
      this.$emit('closeErrorPopup', false);
      this.$emit('authorizationHandler');
    }
  }
}
</script>

<style lang="scss" src="@/styles/ErrorPopup.scss">
</style>
