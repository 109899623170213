<template>
  <div class="choice-ticket__bottom">
    <h3 class="choice-ticket__bottom-title">Популярные вопросы</h3>
    <div class="choice-ticket__bottom-inner">
      <div class="choice-ticket__qustions">
        <div class="choice-ticket__qustions-wrapper">
          <ul class="choice-ticket__qustions-list">
            <li v-for="question in popularQuestions" :key="question.question" class="choice-ticket__qustions-item"
                :class="{opened: question.opened}" @click="question.opened = !question.opened">
              <div class="qustion-header">
                <h4 class="qustion-title">
                  {{ question.question }}
                </h4>
                <svg class="qustion-icon-arrow">
                  <use xlink:href="#ic_arrow-next"></use>
                </svg>
              </div>
              <p class="qustion-content" v-html="question.answer" style="text-align: justify">
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="choice-ticket__point">
        <h3 class="choice-ticket__point-title">Гдe купить билеты оффлайн</h3>
        <ol class="choice-ticket__point-list">
          <li v-for="info in offlineSection" :key="info.address" class="choice-ticket__point-item">
            <div class="choice-ticket__point-header">{{ info.address }}</div>
            <div class="choice-ticket__point-info">
              <span class="choice-ticket__point-desc">{{ info.location }}</span>
              <span class="choice-ticket__point-time">{{ info.time }}</span>
            </div>
          </li>
        </ol>
        <!--
        <a class="choice-ticket__point-link" href="">
          Все точки продаж
          <svg class="icon">
            <use xlink:href="#ic_arrow-right"></use>
          </svg>
        </a>
        -->
      </div>
    </div>
  </div>
</template>

<script>
import popularQuestions from '@/mocks/popularQuestions.json';
import offlineSection from '@/mocks/offlineSection.json';

export default {
  name: 'Questions',
  data() {
    return {
      popularQuestions,
      offlineSection
    }
  },
  methods: {
    greet: function (data) {
      data.opened = !data.opened;
    }
  }
}
</script>

<style lang="scss" src="@/styles/Questions.scss">
</style>
