import {Getters, Mutations, Module, Actions, createMapper} from 'vuex-smart-module';

import orderApi from '@/services/order.api';

export class OrderState {
	seatInOrder;
	printLink;
}

export class OrderGetters extends Getters {
	get seatInOrder() {
		return this.state.seatInOrder;
	}

	get printLink() {
		return this.state.printLink;
	}
}

export class OrderMutations extends Mutations {
	setSeatInOrder(seatInOrder) {
		this.state.seatInOrder = seatInOrder;
	}

	setPrintLink(printLink) {
		this.state.printLink = printLink;
	}
}

export class OrderActions extends Actions {
	async getSeatInOrder(orderId) {
		try {
			const response = await orderApi.getSeatInOrder(orderId);
			this.commit('setSeatInOrder', response);
		} catch ({message}) {
			this.commit('setSeatInOrder', []);
		}
	}

	async printOrderTickets(orderId) {
		return orderApi.printOrderTickets(orderId);
	}
}


export const orderModule = new Module({
	state: OrderState,
	getters: OrderGetters,
	mutations: OrderMutations,
	actions: OrderActions,
});

export const orderMapper = createMapper(orderModule);

export default orderModule;
