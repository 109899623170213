<template>
  <section class="abonements-main__header">
    <div class="abonements-main__banner">
      <img src="https://metallurg.ru/_assets/redesign2020/img/official/abonements-banner.png" alt="">
    </div>
    <h1 class="abonements-main__title abonements-main__title--header">Абонементы на сезон 2024/25</h1>
    <p>
      Продажа абонементов на все домашние матчи игрового сезона открыта с 23&nbsp;июля&nbsp;2024&nbsp;г. на сайте и с в кассах Арены "Металлург"!
      <br/> 
      Полный абонемент включает в себя:
    </p>
    <ul class="abonements-main__list">
      <li>Посещение всех домашних матчей "Металлурга" в регулярном чемпионате КХЛ</li>
      <li>Посещение всех домашних матчей МХК "Стальные Лисы" в регулярном чемпионате МХЛ</li>
      <li>Специальная цена на нехоккейные мероприятия арены "Металлург"</li>
    </ul>
    <div class="abonements-main__actions">
      <button class="abonements-main__button yellow-button" type="button" @click="scrollToKinds">Выбрать абонемент</button>
      <a class="abonements-main__link" href="https://www.metallurg.ru/tickets/abonements">
        <svg>
          <use xlink:href="#ic_pdf"></use>
        </svg>
        <span>
          Абонементная программа 2024-2025
        </span>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AbonementsBanner',
  methods: {
    scrollToKinds() {
      this.$emit('scrollToKinds');
    }
  },
}
</script>
