<template>
  <div class="tickets-popup-order">
    <div @click="closeTicketsPopup()">
      <!-- Обёртка попапа с белым фоном -->
      <div class="tickets-popup-order__wrapper" @click="$event.stopPropagation()">
        <!-- Контнетный блок -->
        <div class="tickets-popup-order__content">
          <!-- Кнопка закрытия попапа -->
          <button class="tickets-popup-order__back" type="button" @click="closeTicketsPopup()">
            <svg>
              <use xlink:href="#ic_arrow_tickets_system"></use>
            </svg>
            к выбору билетов
          </button>
          <!-- Форма покупки билетов -->
          <form>
            <!-- Обёртка внутри формы от тэга формы стилей нет -->
            <div class="tickets-popup-order__inner">
              <h3 class="tickets-popup-order__title">оформление заказа</h3>
              <!-- Блок с серым фоном -->
              <div class="tickets-popup-order__item tickets-popup-order__item--top">
                <!--Заголовок -->
                <p class="tickets-popup-order__item-title">ваши данные</p>
                <!-- серый фон -->
                <div class="tickets-popup-order__item-inner">
                  <!-- Имя пользователя -->
                  <span v-if="principalInfo.name"
                      class="tickets-popup-order__name tickets-popup-order__text">{{ principalInfo.name }} {{
                      principalInfo.lastName
                    }}</span>
                  <!-- номер пользователя -->
                  <span class="tickets-popup-order__text">{{ principalInfo.phone }}</span>
                  <!-- email пользователя -->
                  <span class="tickets-popup-order__text">{{ principalInfo.userEmail }}</span>
                  <div v-if="principalInfo.fanCard" class="tickets-popup-order__cart-inner">
                    <span class="text">Карта болельщика</span>
                    <!-- номер карты болельщика -->
                    <span class="tickets-popup-order__text">{{ principalInfo.fanCard }}</span>
                    <!-- Количество балов на карте -->
                    <span v-if="bonuses && bonuses.balance === '1'"
                          class="tickets-popup-order__text"> {{ bonuses.balance }} бонус</span>
                    <span v-if="bonuses && bonuses.balance >= 2 && bonuses.balance <= 5"
                          class="tickets-popup-order__text"> {{ bonuses.balance }} бонусa</span>
                    <span v-if="bonuses && bonuses.balance >= 6"
                          class="tickets-popup-order__text"> {{ bonuses.balance }} бонусов</span>
                  </div>
                </div>
              </div>
              <!-- Блок с серым фоном / выбраннеые билеты -->
              <div class="tickets-popup-order__item tickets-popup-order__item--fields">
                <!-- Заголовок -->
                <p class="tickets-popup-order__item-title">Выбрано билетов <span>({{ orderReservedSeats.length }})</span>
                </p>
                <!-- серый фон -->
                <div v-for="calendar in groupedTickets" :key="calendar.calendarId"
                    class="tickets-popup-order__item-inner">
                  <!-- Обёртка мероприятия -->
                  <!-- названия команд -->
                  <span class="tickets-popup-order__event-name">{{ calendar.calendarShortName }}</span>
                  <!-- дата мероприятия -->
                  <!-- TODO: Вытянуть день недели-->
                  <span v-if="calendar.calendarDay" class="tickets-popup-order__event-date">{{calendar.calendarDay }}, {{ calendar.calendarTime }}</span>
                  <!-- Обёртка сектора -->
                  <div class="tickets-popup-order__sector" v-for="sector in calendar.sectors" :key="sector.sectorId">
                    <!-- номер сектора -->
                    <span class="tickets-popup-order__sector-number">{{ sector.sectorShortName }}</span>
                    <!-- Список билетов сектора -->
                    <div class="tickets-popup-order__sector-list">
                      <!-- Билет -->
                      <fieldset class="tickets-popup-order__sector-item" v-for="ticket in sector.tickets" :key="ticket.id">
                        <!-- Инпут хранящий id биллета в value -->
                        <input type="hidden" value="">
                        <!-- Визуальная часть билета -->
                        <div class="tickets-popup-order__sector-info">
                          <!-- Место ряд -->
                          <span v-if="ticket.sectorName != 'Ресторан'" class="place">{{ ticket.rowName }} ряд, {{ ticket.seatShortName }} место</span>
                          <span v-else class="place">{{ ticket.rowShortName }}, {{ ticket.seatShortName }} место</span>
                          <!-- Цена -->
                          <span class="price">{{ ticket.price }} ₽</span>
                          <!-- Кнопка удаления билета -->
                          <button class="delete" type="button"
                                  @click="removeSeatItem(ticket.calendarId, ticket.id)"></button>
                        </div>
                        <!-- Чекбокс на льготный билет -->
                        <div v-if="ticket.makeChild === true" class="tickets-popup-order__sector-checkbox">
                          <!-- Иконка с подсказкой -->
                          <div class="icon-inner">
                            <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                viewBox="0 0 18 18">
                              <circle class="stroke" cx="9" cy="9" r="8" stroke-width="2"></circle>
                              <rect class="fill" width="1.5" height="6" x="8.25" y="7.5" rx=".75"></rect>
                              <rect class="fill" width="3" height="1.5" x="6.75" y="7.5" rx=".75"></rect>
                              <rect class="fill" width="4.5" height="1.5" x="6.75" y="12" rx=".75"></rect>
                              <rect class="fill" width="1.5" height="1.5" x="8.25" y="4.5" rx=".75"></rect>
                            </svg>
                            <div class="bubble">
                              <div class="text">Дети от 7 до 18 лет</div>
                              <div class="text">Пенсионеры</div>
                              <div class="text">(При наличии документа для подтверждения)</div>
                            </div>
                          </div>
                          <!-- Чекбокс -->
                          <label class="checkbox-inner">
                            <input v-if="ticket.isChild === true"
                                  class="visually-hidden"
                                  type="checkbox" :value="ticket.id"
                                  v-model="childSeats[ticket.id]"
                                  @click="setSeatType(ticket.calendarId, ticket.id, ticket.isChild)"
                                  checked/>
                            <input v-else
                                  class="visually-hidden"
                                  type="checkbox" :value="ticket.id"
                                  v-model="childSeats[ticket.id]"
                                  @click="setSeatType(ticket.calendarId, ticket.id, ticket.isChild)"
                            />
                            <span class="text-info">Социальный</span>
                            <span class="custom-checkbox"></span>
                          </label>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Блок с серым фоном, но фон будет только на мобилке классы такие же как и у блоков выше -->
              <!--                                <div class="tickets-popup-order__item tickets-popup-order__item&#45;&#45;promo">
                                                  &lt;!&ndash; Заголовок &ndash;&gt;
                                                  <p class="tickets-popup-order__item-title">промокод</p>
                                                  &lt;!&ndash; блок с фоном &ndash;&gt;
                                                  <div class="tickets-popup-order__item-inner">
                                                      &lt;!&ndash; Блок с инпутом и кнопкой &ndash;&gt;
                                                      <div class="tickets-popup-order__promo">
                                                          <input type="text" placeholder="Промокод">
                                                          <button class="accept-promo" type="button" disabled="">
                                                              Применить
                                                              <span class="loader"></span>
                                                          </button>
                                                      </div>
                                                      &lt;!&ndash; Help block для статуса промокода &ndash;&gt;
                                                      <div class="help-promo">
                                                          &lt;!&ndash; Показываем нужный блок в зависимости от ситуации &ndash;&gt;
                                                          &lt;!&ndash; Блок успешного применения промокода &ndash;&gt;
                                                          <div class="success-promo">
                                                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" fill="none" viewBox="0 0 16 14">
                                                                  <path fill="#00ECBA" d="M6.13 12.98a.81.81 0 01-1.16 0L.36 8.36a1.22 1.22 0 010-1.73l.58-.58a1.22 1.22 0 011.73 0l2.88 2.88 7.78-7.78a1.22 1.22 0 011.73 0l.58.58c.48.48.48 1.25 0 1.73l-9.51 9.52z"></path>
                                                              </svg>
                                                              Код применён
                                                          </div>
                                                          &lt;!&ndash; Блок ошибки &ndash;&gt;
                                                          <div class="error-promo">
                                                              <button type="button"></button>
                                                              Код не действителен
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>-->
              <!--                                <div class="tickets-popup-order__item tickets-popup-order__item&#45;&#45;promo">
                                                  &lt;!&ndash; Заголовок &ndash;&gt;
                                                  <p class="tickets-popup-order__item-title">промокод</p>
                                                  &lt;!&ndash; блок с фоном &ndash;&gt;
                                                  <div class="tickets-popup-order__item-inner">
                                                      &lt;!&ndash; Блок с инпутом и кнопкой &ndash;&gt;
                                                      <div class="tickets-popup-order__promo">
                                                          <input type="text" placeholder="Промокод" value="31231bad">
                                                          <button class="accept-promo" type="button">
                                                              Применить
                                                              <span class="loader"></span>
                                                          </button>
                                                      </div>
                                                      &lt;!&ndash; Help block для статуса промокода &ndash;&gt;
                                                      <div class="help-promo">
                                                          &lt;!&ndash; Показываем нужный блок в зависимости от ситуации &ndash;&gt;
                                                          &lt;!&ndash; Блок успешного применения промокода &ndash;&gt;
                                                          <div class="success-promo">
                                                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" fill="none" viewBox="0 0 16 14">
                                                                  <path fill="#00ECBA" d="M6.13 12.98a.81.81 0 01-1.16 0L.36 8.36a1.22 1.22 0 010-1.73l.58-.58a1.22 1.22 0 011.73 0l2.88 2.88 7.78-7.78a1.22 1.22 0 011.73 0l.58.58c.48.48.48 1.25 0 1.73l-9.51 9.52z"></path>
                                                              </svg>
                                                              Код применён
                                                          </div>
                                                          &lt;!&ndash; Блок ошибки &ndash;&gt;
                                                          <div class="error-promo">
                                                              <button type="button"></button>
                                                              Код не действителен
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>-->
              <!--                                <div class="tickets-popup-order__item tickets-popup-order__item&#45;&#45;promo">
                                                  &lt;!&ndash; Заголовок &ndash;&gt;
                                                  <p class="tickets-popup-order__item-title">промокод</p>
                                                  &lt;!&ndash; блок с фоном &ndash;&gt;
                                                  <div class="tickets-popup-order__item-inner">
                                                      &lt;!&ndash; Блок с инпутом и кнопкой &ndash;&gt;
                                                      <div class="tickets-popup-order__promo">
                                                          <input type="text" placeholder="Промокод" value="312vbd" readonly="">
                                                          <button class="accept-promo loading" disabled="" type="button">
                                                              Применить
                                                              <span class="loader"></span>
                                                          </button>
                                                      </div>
                                                      &lt;!&ndash; Help block для статуса промокода &ndash;&gt;
                                                      <div class="help-promo">
                                                          &lt;!&ndash; Показываем нужный блок в зависимости от ситуации &ndash;&gt;
                                                          &lt;!&ndash; Блок успешного применения промокода &ndash;&gt;
                                                          <div class="success-promo">
                                                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" fill="none" viewBox="0 0 16 14">
                                                                  <path fill="#00ECBA" d="M6.13 12.98a.81.81 0 01-1.16 0L.36 8.36a1.22 1.22 0 010-1.73l.58-.58a1.22 1.22 0 011.73 0l2.88 2.88 7.78-7.78a1.22 1.22 0 011.73 0l.58.58c.48.48.48 1.25 0 1.73l-9.51 9.52z"></path>
                                                              </svg>
                                                              Код применён
                                                          </div>
                                                          &lt;!&ndash; Блок ошибки &ndash;&gt;
                                                          <div class="error-promo">
                                                              <button type="button"></button>
                                                              Код не действителен
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>-->
              <div class="tickets-popup-order__item tickets-popup-order__item--promo">
                <!-- Заголовок -->
                <p class="tickets-popup-order__item-title">промокод</p>
                <!-- блок с фоном -->
                <!--<div class="tickets-popup-order__item-inner success">  -->
                <div class="tickets-popup-order__item-inner"
                    :class="{'success': promoCodeSuccess, 'error': promoCodeError}">
                  <!-- Блок с инпутом и кнопкой -->
                  <div class="tickets-popup-order__promo">
                    <input v-if="amountToPay > 0" type="text" placeholder="Промокод" @input="onPromocodeChange" v-model="promoCode"
                          :disabled="promoCodeSuccess">
                    <input v-else type="text" placeholder="Промокод" v-model="promoCode"
                          disabled="">
                    <button v-if="!promoCode" class="accept-promo" type="button" disabled="">
                      Применить
                      <span class="loader"></span>
                    </button>
                    <button v-else class="accept-promo" :disabled="promoPending" :class="{'loading': promoPending}"
                            type="button" @click="checkPromo([orderReservedSeats[0].calendarId,promoCode])">
                      Применить
                      <span class="loader"></span>
                    </button>

                  </div>
                  <!-- Help block для статуса промокода -->
                  <div class="help-promo">
                    <!-- Показываем нужный блок в зависимости от ситуации -->
                    <!-- Блок успешного применения промокода -->
                    <div class="success-promo">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" fill="none" viewBox="0 0 16 14">
                        <path fill="#00ECBA"
                              d="M6.13 12.98a.81.81 0 01-1.16 0L.36 8.36a1.22 1.22 0 010-1.73l.58-.58a1.22 1.22 0 011.73 0l2.88 2.88 7.78-7.78a1.22 1.22 0 011.73 0l.58.58c.48.48.48 1.25 0 1.73l-9.51 9.52z"></path>
                      </svg>
                      Код применён
                    </div>
                    <!-- Блок ошибки -->
                    <div class="error-promo">
                      <button type="button" @click="resetPromoCode"></button>
                      {{ promoMessage }}
                    </div>
                  </div>
                </div>
              </div>
              <!--                                <div class="tickets-popup-order__item tickets-popup-order__item&#45;&#45;promo">
                                                  &lt;!&ndash; Заголовок &ndash;&gt;
                                                  <p class="tickets-popup-order__item-title">промокод</p>
                                                  &lt;!&ndash; блок с фоном &ndash;&gt;
                                                  <div class="tickets-popup-order__item-inner error">
                                                      &lt;!&ndash; Блок с инпутом и кнопкой &ndash;&gt;
                                                      <div class="tickets-popup-order__promo">
                                                          <input type="text" placeholder="Промокод" value="312vbd" readonly="">
                                                          <button class="accept-promo" type="button" disabled="">Применить</button>
                                                      </div>
                                                      &lt;!&ndash; Help block для статуса промокода &ndash;&gt;
                                                      <div class="help-promo">
                                                          &lt;!&ndash; Показываем нужный блок в зависимости от ситуации &ndash;&gt;
                                                          &lt;!&ndash; Блок успешного применения промокода &ndash;&gt;
                                                          <div class="success-promo">
                                                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" fill="none" viewBox="0 0 16 14">
                                                                  <path fill="#00ECBA" d="M6.13 12.98a.81.81 0 01-1.16 0L.36 8.36a1.22 1.22 0 010-1.73l.58-.58a1.22 1.22 0 011.73 0l2.88 2.88 7.78-7.78a1.22 1.22 0 011.73 0l.58.58c.48.48.48 1.25 0 1.73l-9.51 9.52z"></path>
                                                              </svg>
                                                              Код применён
                                                          </div>
                                                          &lt;!&ndash; Блок ошибки &ndash;&gt;
                                                          <div class="error-promo">
                                                              <button type="button"></button>
                                                              Код не действителен
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>-->
              <div class="tickets-popup-order__item tickets-popup-order__item--bottom">
                <p class="tickets-popup-order__item-title">оплата</p>
                <div class="tickets-popup-order__item-inner">
                  <div class="tickets-popup-order__bonus">
                    <div class="tickets-popup-order__bonus-inner">
                      <span class="text">Сколько бонусов вы хотите списать</span>
                      <div class="icon-inner" onclick="">
                                                      <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24"
                                                          height="24" fill="none" viewBox="0 0 24 24">
                                                          <path fill-rule="evenodd"
                                                                d="M12 22a10 10 0 100-20 10 10 0 000 20zm0 2a12 12 0 100-24 12 12 0 000 24z"
                                                                clip-rule="evenodd"></path>
                                                          <path fill-rule="evenodd"
                                                                d="M10.31 8.97c.26-.61.7-.92 1.33-.92.42 0 .74.12.95.35.21.24.32.55.32.93 0 .3-.06.54-.17.74-.12.2-.27.38-.46.55l-.57.53a3.46 3.46 0 00-1.06 1.54c-.25.68.39 1.31 1.12 1.31h.68c0-.36.08-.67.2-.91.14-.26.3-.48.48-.68.2-.2.4-.39.61-.56.22-.18.42-.39.6-.62.2-.24.35-.52.47-.84.13-.33.19-.73.19-1.22a3.24 3.24 0 00-.93-2.29A3.71 3.71 0 0011.64 6a3.4 3.4 0 00-2.5.96c-.46.46-.79 1.06-.98 1.8-.13.5.22.99.72 1.1.62.16 1.19-.3 1.43-.89zM11.44 16a1 1 0 100 2 1 1 0 000-2z"
                                                                clip-rule="evenodd"></path>
                                                      </svg>
                                                      <span class="bubble">Оплатить бонусами можно не более 50% от стоимости заказа</span>
                                                  </div>
                    </div>
                    <div class="field-inner">
                      <input v-if="amountToPay > 0" type="text" @keypress="isNumber($event)" @input="onBonusesAmountChange" v-model="bonusesAmount"
                            placeholder="0 ₽">
                      <input v-else type="text" disabled=""
                            v-model="bonusesAmount"
                            placeholder="0 ₽">
                    </div>
                    <button class="accept-bonus" type="button" @click="calculateBonusesDiscount()">
                      ок
                      <span class="loader"></span>
                    </button>
                  </div>
                  <div class="tickets-popup-order__bottom">
                    <p class="tickets-popup-order__item-title">ваши Билеты</p>
                    <ul class="tickets-popup-order__bottom-list">
                      <li class="list-item">
                        <span v-if="orderReservedSeats.length ===1" class="name">{{
                            orderReservedSeats.length
                          }} билет</span>
                        <span v-if="orderReservedSeats.length >=2 && orderReservedSeats.length <=4"
                              class="name">{{ orderReservedSeats.length }} билета</span>
                        <span v-if="orderReservedSeats.length >=5" class="name">{{
                            orderReservedSeats.length
                          }} билетов</span>
                        <span class="price">{{ totalPrice }} ₽</span>
                      </li>
                      <li v-if="totalPrice > 0 && promocodeDiscount !== null" class="list-item">
                        <span class="name">Скидка по промокоду</span>
                        <span class="price price--red">— {{ promocodeDiscount }}  ₽</span>
                      </li>
                      <li v-if="totalPrice > 0 && bonusesDiscount > 0" class="list-item">
                        <span class="name">Будет оплачено бонусами</span>
                        <span class="price price--red">— {{ bonusesDiscount }} ₽</span>
                      </li>
                    </ul>
                    <div class="tickets-popup-order__total">
                      <span>итого</span>
                      <span class="total-price"> {{ amountToPay }} </span>
                    </div>
                  </div>
                  <div class="tickets-popup-order__submit-inner">
                    <!-- <div class="button-inner">
                        <span class="text">+ 300 бонусов после оплаты</span> -->
                    <div class="button-inner" style="justify-content:center">
                      <button v-if="amountToPay > 0" class="yellow-button"
                              :class="{'loading': isLoading}"
                              :disabled="isLoading"
                              type="button" @click="pay()">
                        Перейти к оплате
                        <span class="loader"></span>
                      </button>
                      <button v-else-if="amountToPay === 0 && reservedSeats.length > 0" class="yellow-button" type="button" @click="pay()">
                        Получить
                        <span class="loader"></span>
                      </button>
                      <button v-else-if="reservedSeats.length === 0" class="yellow-button" type="button" @click="closeTicketsPopup()" >
                        Закрыть
                        <span class="loader"></span>
                      </button>
                      <button v-else class="yellow-button" type="button" @click="pay()">
                        Перейти к оплате
                        <span class="loader"></span>
                      </button>
                    </div>
                    <p class="personal-data">Приобретая билеты, вы соглашаетесь
                      c <a href="https://www.metallurg.ru/privacy-policy.pdf" target="_blank">условиями обработки персональных данных</a></p>
                    <div class="tickets-popup-order__glossary glossary">
                      <a class=" glossary__text" href="https://tickets.metallurg.ru/resources/files/ticket_return.pdf" target="_blank">
                        <div class="glossary__icon-holder">
                          <svg class="i-info">
                            <use xlink:href="#ic_info"></use>
                          </svg>
                          <svg class="i-info-blue">
                            <use xlink:href="#ic_info-blue"></use>
                          </svg>
                          <svg class="i-close">
                            <use xlink:href="#ic_close"></use>
                          </svg>
                        </div>
                        <span>Возврат билетов</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <ErrorPopup
          v-if="isErrorPopupShown"
          @closeErrorPopup="closeErrorPopup"
          :errorMessage="errorMessage"
      />
  </div>
</template>

<script>
import {calendarMapper} from '@/store/modules/calendar';
import moment from 'moment';

import ErrorPopup from './ErrorPopup.vue';

export default {
  name: 'TicketsPopupOrder',
  data() {
    return {
      childSeats: [],
      isErrorPopupShown: false,
      errorMessage: '',
      bonusesAmount: null,
      promoCode: '',
      promoPending: false,
      promoCodeSuccess: false,
      promoCodeError: false,
      promocodeDiscount: null,
      bonusesDiscount: null,
      promoMessage: '',
      groupedTickets: [],
      isLoading: false,
    }
  },
  components: {
    ErrorPopup,
  },
  props: {
    orderReservedSeats: {type: Array},
    principalInfo: {type: Object},
  },
  watch: {
    orderReservedSeats: {
      immediate: true,
      handler(reservedSeats) {
        const compareStrings = (a, b) => {
          if (a < b) {
            return -1;
          } else if (a > b) {
            return 1;
          }
          return 0;
        }

        const eventIds = [];
        const eventsMap = reservedSeats.reduce((accumulator, seat) => {
          if(!accumulator[seat.calendarId]) {
            accumulator[seat.calendarId] = [];
            eventIds.push(seat.calendarId);
          }
          accumulator[seat.calendarId].push(seat);
          return accumulator;
        }, {});
        this.groupedTickets = eventIds.map(eventId => {
          const eventTickets = eventsMap[eventId];

          const sectorIds = [];
          const sectorsMap = eventTickets.reduce((accumulator, seat) => {
            if(!accumulator[seat.sectorId]) {
              accumulator[seat.sectorId] = [];
              sectorIds.push(seat.sectorId);
            }
            accumulator[seat.sectorId].push(seat);
            return accumulator;
          }, {});

          const sectors = sectorIds.map(sectorId => {
            const sectorTickets = sectorsMap[sectorId];

            const sectorName = sectorTickets[0].sectorName;

            return {
              sectorShortName: sectorTickets[0].sectorShortName,
              sectorName: sectorName,
              sectorId: sectorTickets[0].sectorId,
              tickets: sectorTickets
                  .sort((a, b) => {
                    if (sectorName.indexOf('Ресторан') > -1) {
                      const rowCompare = compareStrings(a.rowShortName, b.rowShortName);
                      if (rowCompare === 0) {
                        return +a.seatShortName - +b.seatShortName;
                      }
                      return rowCompare;
                    }
                    const rowCompare = +a.rowName - +b.rowName;
                    if (rowCompare === 0) {
                      return +a.seatShortName - +b.seatShortName;
                    }
                    return rowCompare;
                  })
            }
          }).sort((a, b) => {
            if (a.sectorShortName.indexOf('Ресторан') > -1) {
              return -1;
            }
            if(b.sectorShortName.indexOf('Ресторан') > -1) {
              return 1;
            }
            return +a.sectorName - +b.sectorName;
          });

          const calendarDay = eventTickets[0].calendarDay
          const calendarTime = eventTickets[0].calendarTime;
          const calendarDate = calendarDay ? new Date(calendarDay.split('.').reverse().join('.') + ' ' + calendarTime).getTime() : 0;

          return {
            calendarDate: calendarDate,
            calendarShortName: eventTickets[0].calendarShortName,
            calendarDay: calendarDay ? moment(calendarDay, 'DD.MM.YYYY HH:mm').locale('ru').format('DD MMMM YYYY , (dddd)') : null,
            calendarTime: calendarTime,
            calendarId: eventTickets[0].calendarId,
            sectors
          }
        })
        .sort((a, b) => {
          if (a.calendarDate === 0 && b.calendarDate === 0) {
            return compareStrings(a.calendarShortName, b.calendarShortName);
          }
          return a.calendarDate - b.calendarDate;
        });
      }
    }
  },
  async mounted() {
    // TODO: As soon as the "orderId" property appears get bonuses 
    await this.getBonuses();
    this.stopTicketsPopupLoading();

    document.body.addEventListener('click', event => {
      if (event.target.closest('.tickets-popup-order__bonus-inner')) {
        document.activeElement = null
      }
    })
  },

  computed: {
    ...calendarMapper.mapGetters(['seatType', 'bonuses', 'promocode', 'reservedSeats']),
    totalPrice() {
      let price = 0;
      this.orderReservedSeats.filter(seat => {
        price += Number(seat.price);
      });
      return price;
    },
    amountToPay() {
      if (this.totalPrice > 0) {
        return this.totalPrice
            - (this.promocodeDiscount === null ? 0 : this.promocodeDiscount)
            - (this.bonusesDiscount === null ? 0 : this.bonusesDiscount)
      } else {
        return this.totalPrice
      }
    }
  },
  methods: {
    ...calendarMapper.mapActions([
      'toggleSeatType',
      'getReservedSeatsList',
      'setEmptyReservedSeats',
      'getBonuses',
      'checkPromoCode',
      'unreserveSeat',
      'createOrder'
    ]),
    stopTicketsPopupLoading() {
      this.$emit('stopTicketsPopupLoading', false);
    },
    async setSeatType(calendarId, seatId, isChild) {
      try {
        const response = await this.toggleSeatType({calendarId, seatId, isChild});
        this.reservedSeats
            .filter(seat => {
              return seat.id === seatId && seat.calendarId === calendarId;
            })
            .forEach(seat => {
              seat.isChild = !seat.isChild;
              seat.price = +response.price;
            });
      } catch (e) {
        this.reservedSeats
            .filter(seat => {
              return seat.id === seatId && seat.calendarId === calendarId;
            })
            .forEach(seat => {
              if (!seat.isChild) {
                delete this.childSeats[seat.id];
              } else {
                this.childSeats[seat.id] = true;
              }
            });
        this.errorMessage = e.message;
        this.isErrorPopupShown = true;
      }
    },
    async checkPromo([calendarId, promoCode]) {
      this.promoPending = true;
      try {
        await this.checkPromoCode([calendarId, promoCode]);
        this.promoCodeSuccess = true;
        let discount = 0;
        this.promoMessage = '';
        if (this.promocode.balance > 0) {
          discount = Math.min(this.totalPrice, this.promocode.balance);
        } else {
          discount = Math.round(this.totalPrice * this.promocode.percent / 100);
        }
        this.promocodeDiscount = discount;
        this.bonusesDiscount = 0;
        this.bonusesAmount = 0;
      } catch (e) {
        this.promoCodeError = true;
        this.promoMessage = 'Код не действителен';//this.promocode.errorCode;
      } finally {
        this.promoPending = false;
      }
    },
    async removeSeatItem(calendarId, seatId) {
      const unreserveInfo = {
        quantity: 1,
        seatId: seatId
      };
      await this.unreserveSeat({calendarId: calendarId, unreserveInfo});
      if (this.groupedTickets.length === 0) {
        this.bonusesDiscount = 0;
        this.bonusesAmount = 0;
        const node = document.getElementById(unreserveInfo.seatId);
        if (node) {
          node.classList.add('available');
          node.classList.remove('reserved');
        }
        this.closeTicketsPopup();
      } else {
        this.bonusesDiscount = 0;
        this.bonusesAmount = 0;
        this.isBonusLoading = true;
        await this.getBonuses();
        this.isBonusLoading = false;
        if (this.promoCode && this.promoCode != ''){
          this.checkPromo([calendarId, this.promoCode]);
        }
        if (calendarId === +this.$route.params.calendarId) {
        const node = document.getElementById(unreserveInfo.seatId);
          if (node) {
            node.classList.add('available');
            node.classList.remove('reserved');
          }
        }
      }
    },
    closeTicketsPopup() {
      this.$emit('closeTicketsPopup', false);
    },
    closeErrorPopup(isShowModal) {
      this.isErrorPopupShown = isShowModal;
    },
    async pay() {
      try {
        this.isLoading = true;
        if (this.promoCodeError) {
          this.promoCode = null;
        }
        const response = await this.createOrder({
          paymentAmount: +this.bonusesDiscount || null,
          promoCode: this.promoCode || null
        });
        this.setEmptyReservedSeats();
        const wrapTable = document.querySelector('#scheme table table');
        const reservedList = wrapTable.querySelectorAll('.seat.reserved');
        reservedList.forEach(seat => {
          seat.classList.add('unavailable');
          seat.classList.remove('reserved');
        });
        this.closeTicketsPopup();

        const container = document.createElement('div');
        container.innerHTML = response;

        document.body.appendChild(container);
        container.querySelector('form').submit();
        this.isLoading = false;
      } catch (e) {
        this.errorMessage = e.errorMessage;
        this.isErrorPopupShown = true;
        await this.getReservedSeatsList();
        this.isLoading = false;
      }
    },
    onBonusesAmountChange() {

    },

    calculateBonusesDiscount() {
      const bonuses = +this.bonusesAmount;
      if (isNaN(bonuses) || bonuses == 0) {
        this.bonusesDiscount = null;
        return;
      }
      const half = Math.floor(this.totalPrice / 2);
      const balance = this.bonuses.balance;
      const minPrice = this.bonuses.minPrice;
      if (minPrice > balance) {
        this.bonusesDiscount = null;
        return;
      }
      if (bonuses < minPrice) {
        this.bonusesDiscount = Math.min(minPrice, half, balance);
      } else {
        this.bonusesDiscount = Math.min(bonuses, half, balance);
      }
      this.bonusesAmount = this.bonusesDiscount.toString();
      this.resetPromoCode();
    },

    onPromocodeChange() {
      this.promocodeDiscount = null;
      this.promoCodeError = false;
      this.promoCodeSuccess = false;
    },

    resetPromoCode() {
      this.promocodeDiscount = null;
      this.promoCodeError = false;
      this.promoCodeSuccess = false;
      this.promoCode = '';
    },

    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
}
</script>
