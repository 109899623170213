<template>
  <section class="abonements-main__faq">
    <div class="abonements-main__container">
      <h2 class="abonements-main__title">Популярные вопросы</h2>
      <div class="abonements-main__faq-wrapper">
        <ul class="abonements-main__question-list accordion">
          <li 
            v-for="question in abonementsQuestions" 
            :key="question.question"
            class="choice-ticket__qustions-item"
            :class="{opened: question.opened}" @click="question.opened = !question.opened"
          >
            <div class="accordion-item__wrapper">
              <div class="qustion-header">
                <h3 class="qustion-title">{{ question.question }}</h3>
                <button type="button" class="accordion-item__button">
                  <svg class="qustion-icon-arrow">
                    <use xlink:href="#ic_arrow-next"></use>
                  </svg>
                </button>
              </div>
              <p class="qustion-content" v-html="question.answer" style="text-align: justify"></p>
            </div>
          </li>
        </ul>
        <div class="abonements-main__faq-col">
          <div class="abonements-faq abonements-main__question">
            <h3 class="abonements-faq__title">Где купить абонемент оффлайн</h3>
            <p class="abonements-faq__main-text">Все виды абонементов можно купить в кассах «Арены «Металлург»</p>
            <p>Для покупки или оформления социального абонемента нужна письменная заявка покупателя и наличие документа, удостоверяющего         соответствующую льготу.
            </p>
            <p  class="abonements-faq__note">
              Кассы работают с 09:00 до 20:30.
            </p>
          </div>
          <div class="abonements-faq abonements-main__question">
            <h3 class="abonements-faq__title">Рассрочка платежа</h3>
            <ol class="abonements-faq__list">
              <li class="abonements-faq__item">
                <p class="abonements-faq__main-text">На 3 месяца — в кассе Арены</p>
                <p>Оформить рассрочку можно в кассе Арены при наличии паспорта.</p>
                <p>Каждая оплата производится непосредственно в кассе Арены «Металлург» наличным или безналичным способом.</p>
              </li>
              <li class="abonements-faq__item">
                <p class="abonements-faq__main-text">На 3 месяца — через «Карту Металлурга» от&nbsp;<span class="abonements-faq__card">АО&nbsp;«Кредит Урал Банк».</span>
                </p>
                <p>
                  Каждая оплата производится автоматически с вашего карточного счета, к которому привязана зарплатная «Карта
                  Металлурга» от АО «Кредит Урал Банк». </p>
                <p>Оформить договор можно через представителя клуба в вашем структурном подразделении Группы ММК или в кассе Арены «Металлург» при наличии паспорта и «Карты Металлурга».</p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import abonementsQuestions from '@/mocks/abonementsQuestions.json';

export default {
  name: 'AbonementsFAQ',
  data() {
    return {
      abonementsQuestions
    }
  },
}
</script>
