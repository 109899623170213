<template>
  <div v-if="seatInOrder">
    <div class="success-order-pay">
      <h1 v-if="seatInOrder.payStatus === 'PAID'" class="success-order-pay__title">Ваш заказ {{this.$route.params.orderId}} успешно оплачен</h1>
      <h1 v-if="seatInOrder.payStatus != 'PAID' && seatInOrder.payStatus" class="success-order-pay__title">Ваш заказ {{this.$route.params.orderId}} не оплачен</h1>
      <h1 v-if="!seatInOrder.payStatus" class="success-order-pay__title">Заказ {{this.$route.params.orderId}} не найден</h1>
      <div class="success-order-pay__tickets-info">
        <p v-if="seatInOrder.status === 'ISSUED'" class="success-order-pay__tickets-info-title">Мы отправили билеты и квитанцию об оплате на эл. почту {{seatInOrder.email}}</p>
        <p class="success-order-pay__tickets-info-subtitle">
          Билеты также хранятся в вашем <a class="success-order-pay__section-link" href="https://www.metallurg.ru/profile/profile/">личном кабинете.</a> 
        </p>
        <p v-if="seatInOrder.payStatus === 'PAID'" class="success-order-pay__tickets-info-subtitle">Дата и время оплаты: 
          {{ moment(seatInOrder.orderPaymentDate,'DD.MM.YYYY').locale('ru').format('DD MMMM YYYY') }} г. в 
          {{ moment(seatInOrder.orderPaymentDate,'DD.MM.YYYY HH:mm').locale('ru').format('HH:mm') }}
        </p>
      </div> 
      <div v-if="seatInOrder.payStatus" class="success-order-pay__order-info">
        <p class="success-order-pay__order-info-title">Стоимость заказа: {{seatInOrder.orderPrice}} ₽ .</p>
      <!-- <p class="success-order-pay__order-info-title">Начислено на карту болельщика: 300 бонусов</p> -->
        <p class="success-order-pay__order-info-subtitle">
          Общее количество бонусов можно посмотреть в <a class="success-order-pay__section-link">личном кабинете</a> на сайте.
        </p>
      </div> 
      <div v-if="seatInOrder.payStatus" class="success-order-pay__order-list">
        <p class="success-order-pay__order-list-title">Состав заказа:</p>
        <div v-for="seat in seatsInOrder" :key="seat.id" class="success-order-pay__order-section">
          <p class="success-order-pay__order-list-subtitle">
            {{seat.length}} билета на матч {{seat[0].calendarName}} {{ moment(seat[0].calendarDay,'DD.MM.YYYY').locale('ru').format('DD MMMM (dddd)') }} в {{ `${seat[0].calendarTime.substr(0, 5)}` }}</p>
          <div class="success-order-pay__places">
            <p class="success-order-pay__order-list-places">Места: </p>
            <div class="success-order-pay__places-list">
              <ul>
                <li class="success-order-pay__order-list-item" v-for="innerSeat in seat" :key="innerSeat.id">{{innerSeat.name}}</li>
              </ul>
            </div>
          </div>
        </div>  
      </div>  
      <div class="success-order-pay__actions">
        <button v-if="seatInOrder.payStatus === 'PAID'"
          type="button"
          class="yellow-button" style="margin-right: 32px;"
          @click="printTickets(seatInOrder.orderId)" 
        >
          <span  class="success-order-pay__print-tickets-title">Распечатать билеты</span>
        </button>
        <router-link :to="{ name: 'main' }">
          <button class="success-order-pay__back-to-site-error" v-if="seatInOrder.payStatus != 'PAID'">
            <span class="success-order-pay__back-to-site-title">Вернуться на сайт</span>
          </button>
          <button class="success-order-pay__back-to-site" v-if="seatInOrder.payStatus === 'PAID'">
            <span class="success-order-pay__back-to-site-title">Вернуться на сайт</span>
          </button>
        </router-link>
      </div>
    </div>
    <ErrorPopup
      v-if="isErrorPopupShown"
      @closeErrorPopup="closeErrorPopup"
      :errorMessage="errorMessage"
    />
  </div>
</template>

<script>
import { orderMapper } from '@/store/modules/order';
import {authorizationMapper} from '@/store/modules/authorization';

import ErrorPopup from './ErrorPopup.vue';

import * as printJS from 'print-js';

export default {
  name: 'SuccessOrderPay',
  data() {
    return {
      isErrorPopupShown: false,
      errorMessage: '',
    }
  },
  components: {
    ErrorPopup,
  },
  async mounted() {
    await this.getSeatInOrder(this.$route.params.orderId);
    await this.simpleAuthLogout();
   // this.orderId = this.$route.params.orderId;
  },
  computed: {
    ...orderMapper.mapGetters(['seatInOrder', 'printLink']),
    seatsInOrder() {
      return this.seatInOrder.orderSeats.reduce((result, item) => {
      if (result[item.calendarId]) {
        result[item.calendarId].push(item)
      } else {
        result = {...result, [item.calendarId]: [item]}
      }

      return result;
      }, {})
    },
  },  
  methods: {
    ...orderMapper.mapActions(['getSeatInOrder', 'printOrderTickets']),
    ...authorizationMapper.mapActions(['simpleAuthLogout']),
    async printTickets(orderId) {
      try {
        const pdf = await this.printOrderTickets(orderId);

        const pdfUrl = URL.createObjectURL(pdf);
        printJS({printable: pdfUrl, type: 'pdf'});
      } catch (error) {
        console.log(error);
        this.errorMessage = error.message;
        this.isErrorPopupShown = true;
      }
    },
    closeErrorPopup(isShowModal) {
      this.isErrorPopupShown = isShowModal;
    },
  },
}
</script>

<style lang="scss" src="@/styles/SuccessOrderPay.scss">
</style>
