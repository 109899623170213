<template>
  <div>
    <h1 v-if="gameInfo.ownerTeamName && gameInfo.guestTeamName">{{ `${gameInfo.ownerTeamName}` }} — {{ `${gameInfo.guestTeamName}` }} </h1>
    <h1 v-if="!gameInfo.ownerTeamName && gameInfo.guestTeamName">{{ `${gameInfo.guestTeamName}` }} </h1>
    <h1 v-if="!gameInfo.ownerTeamName && !gameInfo.guestTeamName">{{ `${gameInfo.name}` }} </h1>
    <div class="tickets-system-local__desc">
        <h3 v-if="gameInfo.algorithmSaleId === 510">{{ moment(gameInfo.day).locale('ru').format('DD MMMM (dddd)') }} в {{ `${gameInfo.time.substr(0, 5)}` }}</h3>
        <p class="text" v-if="gameInfo.algorithmSaleId === 510">
          {{ `${gameInfo.actionTypeWeb[0].name}` }}. Начало матча по магнитогорскому времени — {{ `${gameInfo.time.substr(0, 5)}` }}.
        </p>
        <p class="text" v-if="gameInfo.algorithmSaleId === 520 || gameInfo.algorithmSaleId === 530">
          <!-- {{ `${gameInfo.actionTypeWeb[0].name}` }} -->
          Мы начинаем продажу абонементов на сезон 2024/25! В стоимость традиционно входит посещение игр «Металлурга» в регулярном чемпионате КХЛ, матчей Мемориала Ромазана, который пройдёт в этом году в формате встреч молодёжных команд, и всех домашних матчей «Стальных Лисов» в регулярном чемпионате и плей-офф
        </p>
        <div v-if="gameInfo.algorithmSaleId === 520 || gameInfo.algorithmSaleId === 530" style="margin-top: 10px;" class="choice-ticket__slider-downloads">
        <a class="choice-ticket__slider-link" href="https://www.metallurg.ru/upload/KHL_MMG.pdf" target="_blank">
          <svg>
            <use xlink:href="#ic_download"></use>
          </svg>
          Скачать расписание матчей
        </a>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'GameMainInfo',
  props: { 
    gameInfo : { type: Object}
  }
}
</script>
